import React from "react";
import DefaultButton from "./buttons/DefaultButton";
import { v4 as uuidv4 } from "uuid";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled from 'styled-components'

const StyledBlogPostPreview = styled.div`
    text-align: center;

    @media(min-width: 992px) {
        text-align: left;
    }
`

const BlogPostPreview = (props) => (
  <StyledBlogPostPreview className={`wp-post__preview mb-5 ${props.className}`} key={uuidv4()}>
    <Link to={"/blog/" + props.post.categories.nodes[0].slug + '/' + props.post.slug + '/'}>
      <GatsbyImage
        image={getImage(props.post.featuredImage.node.localFile)}
        className="img-fluid mb-2 mx-auto"
        alt="Thumbnail"
      />
    </Link>
    <span className="fs-5">{props.post.categories && props.post.categories.nodes[0].name}</span>
    <hr />
    <h3 className="wp-post__title--yellow fs-4">{props.post.title}</h3>
    <div
      dangerouslySetInnerHTML={{
        __html: props.post.excerpt.split(" ").slice(0, 20).join(" ") + "...",
      }}
    />
    <DefaultButton
      title="Scopri di più"
      className="mt-1 border-0 btn-outline-dark"
      bgColor="#f1f1f1"
      url={"/blog/" + props.post.categories.nodes[0].slug + '/' + props.post.slug + '/'}
      arrow
    />
  </StyledBlogPostPreview>
);

export default BlogPostPreview;
